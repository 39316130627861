<template>
  <div class="home">
    <v-container>
      <HomeHeader type="Pre-Bid" />
      <v-dialog v-model="overlay" max-width="500">
        <div align="center">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>
                  <br />
                  <h2>Hi {{ person }}, Welcome to the new DIP!</h2>
                  <br />
                  <br />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3>Get started here to learn more about the DIP</h3>
                  <v-btn
                    color="blue"
                    class="white--text"
                    @click="overlay = false"
                    href="https://moodle.arup.com/course/view.php?id=5841%C2%A7ion%3D7"
                    target="_blank"
                  >
                    Go to Moodle Page
                  </v-btn>
                </v-col>
              </v-row>
              <v-row justify="space-around">
                <v-col>
                  <br />
                  <h3>Get help on DIP specific features</h3>
                  <v-btn
                    color="blue"
                    class="white--text"
                    @click="
                      overlay = false;
                      navigate('Help');
                    "
                  >
                    Go to DIP Help Page
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <br />
                  <h3>I'm ready to start</h3>
                  <v-btn
                    color="blue"
                    class="white--text"
                    @click="overlay = false"
                  >
                    Take me to the DIP
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>

      <div v-if="showWarning">
        <v-row>
          <v-col>
            <v-alert dense border="left" type="warning">
              You are on the test server! Feel free to create DIPs for testing
              purposes.
              <strong
                >Data could be removed at anytime on this site. <br />
                For the production website please visit:
                <a target="_blank" href="https://dip.arup.com"
                  >https://dip.arup.com</a
                >
              </strong>
            </v-alert>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col>
          <h3>
            DIPs I own
            <v-tooltip right max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span
                >These are plans you have started and are a contributor
                for</span
              >
            </v-tooltip>
          </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ProjectDisplayTable
            v-bind:projectsList="dipsOwned"
            v-bind:loadingData="loadingOwner"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>
            DIPs I am involved in
            <v-tooltip right max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span
                >These are plans that you have been assigned an action to or are
                registered as a workshop attendee</span
              >
            </v-tooltip>
          </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ProjectDisplayTable
            v-bind:projectsList="dipsInvolved"
            v-bind:loadingData="loadingAttendee"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ProjectDisplayTable from "@/components/ProjectDisplayTable.vue";
import HomeHeader from "@/components/HomeHeader.vue";

export default {
  name: "Home",
  components: {
    ProjectDisplayTable,
    HomeHeader,
  },
  data: () => ({
    overlay: false,
    dipsOwned: [],
    dipsInvolved: [],
    type: false,
    loadingOwner: false,
    loadingAttendee: false,
    loading: false,
  }),
  computed: {
    showWarning() {
      if (
        this.$store.state.ApiUri !=
        "https://dip-prod-function-app.azurewebsites.net/api"
      ) {
        return true;
      } else {
        return false;
      }
    },

    person() {
      return this.$msal.getUser().username.split(".")[0];
    },
  },
  methods: {
    navigate(routeName) {
      console.log(`navigation to ${routeName}...`);
      this.$router.push({ name: `${routeName}` });
    },
    async fetchData() {
      this.dipsOwned = [];
      this.dipsInvolved = [];

      if (this.$route.query.project) {
        if (this.$route.query.type) {
          // Load up the prebid project
          try {
            this.loading = true;
            const response = await this.$dip.get(
              `${this.$store.state.ApiUri}/GetPreBidResults?ProjectCode=${this.$route.query.project}&DataLevel=Full` // change to jobcode?
            );
            console.log("testing below");
            console.log(response.data);

            if (response.data === "No results found.") {
              this.$router.push({ name: "Home" });
            } else {
              this.$store.state.prebidPlan = response.data[0];
              this.$session.set("planData", this.$store.state.prebidPlan);
              this.loading = false;
              this.$router.push({ name: "PrebidForm" });
              console.log("PreBid loaded.");
            }
          } catch (error) {
            console.log(error.response);
            this.loading = false;
            console.log("Error loading Prebid.");
          }
        } else {
          // Load up the DIP project
          try {
            this.loading = true;
            const response = await this.$dip.get(
              `${this.$store.state.ApiUri}/GetResults?JobCode=${this.$route.query.project}&DataLevel=Full`
            );
            console.log("testing below");
            console.log(response.data);

            if (response.data === "No results found.") {
              this.$router.push({ name: "Home" });
            } else {
              this.$store.state.plan = response.data[0];
              this.$session.set("planData", this.$store.state.plan);
              this.loading = false;
              this.$router.push({ name: "Report" });
              console.log("DIP loaded.");
            }
          } catch (error) {
            console.log(error.response);
            this.loading = false;
            console.log("Error loading DIP.");
          }
        }
      } else {
        console.log("regular startup");
      }

      // Get owned DIPS
      try {
        this.loadingOwner = true;
        const response = await this.$dip.get(
          `${this.$store.state.ApiUri}/GetResults?Owner=${
            this.$msal.getUser().username
          }&DataLevel=Overview`
        );

        const r = response.data.map((x) => {
          x.Type = "DIP";
          if (!x.JobCode) {
            x.JobCode = x.ProjectCode;
          }
          if (!x.JobNumber) {
            x.JobNumber = x.ProjectCode.substring(0, 6); // JobNumber is first 6 digits of ProjectCode
          }
          return x;
        });

        this.dipsOwned = this.dipsOwned.concat(r);
        this.overlay = false;
        this.loadingOwner = false;
      } catch (error) {
        console.log(error.response);
        this.loadingOwner = false;
      }

      // Get involved DIPS
      try {
        const response = await this.$dip.get(
          `${this.$store.state.ApiUri}/GetResults?Attendee=${
            this.$msal.getUser().username
          }&DataLevel=Overview`,
          {
            headers: {
              Authorization: "Bearer " + this.$msal.getUser().token,
            },
          }
        );

        if (typeof response.data == "string") {
          // this.dipsInvolved = [];
        } else {
          const r = response.data.map((x) => {
            x.Type = "DIP";
            if (!x.JobCode) {
              x.JobCode = x.ProjectCode;
            }
            if (!x.JobNumber) {
              x.JobNumber = x.ProjectCode.substring(0, 6); // JobNumber is first 6 digits of ProjectCode
            }
            return x;
          });
          this.dipsInvolved = this.dipsInvolved.concat(r);
        }

        this.loadingAttendee = false;
      } catch (error) {
        console.log(error.response);
        this.loadingAttendee = false;
      }

      // Get owned prebids
      try {
        const response = await this.$dip.get(
          `${this.$store.state.ApiUri}/GetPreBidResults?Owner=${
            this.$msal.getUser().username
          }&DataLevel=Overview`
        );

        console.log(response.data);
        if (typeof response.data == "string") {
          // this.dipsOwned = [];
          // this.overlay = true;
        } else {
          const r = response.data.map((x) => {
            x.Type = "Pre Bid";
            return x;
          });
          this.dipsOwned = this.dipsOwned.concat(r);
          this.overlay = false;
        }

        this.loadingOwner = false;
      } catch (error) {
        console.log(error.response);
        this.loadingOwner = false;
      }

      // Get prebids involved in
      try {
        const response = await this.$dip.get(
          `${this.$store.state.ApiUri}/GetPreBidResults?Attendee=${
            this.$msal.getUser().username
          }&DataLevel=Overview`
        );

        if (typeof response.data == "string") {
          // this.dipsInvolved = [];
        } else {
          const r = response.data.map((x) => {
            x.Type = "Pre Bid";
            return x;
          });
          this.dipsInvolved = this.dipsInvolved.concat(r);
        }

        this.loadingAttendee = false;
      } catch (error) {
        console.log(error.response);
        this.loadingAttendee = false;
      }
      // finally do a check to see if we need to render the welcome box
      if (this.dipsOwned.length == 0 && this.dipsInvolved.length == 0) {
        this.overlay = true;
      }
    },
  },

  mounted() {
    this.fetchData();
    console.log(this.ApiUri);
  },
};
</script>

<style src="../styles/table.scss" lang="scss"></style>
