<template>
  <div align="right">
    <v-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" dark v-on="on" block>
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          Create New DIP
        </v-btn>
      </template>

      <v-stepper v-model="step">
        <v-stepper-header class="px-16">
          <v-stepper-step :complete="step > 1" step="1">
            Create a DIP or Prebid
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="step > 2" step="2">
            Choose Job Number
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-row>
            <v-col cols="1">
              <v-btn
                @click="step = 1"
                v-if="step > 1"
                class="mt-5"
                text
                x-large
              >
                <v-icon x-large> mdi-arrow-left </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="11">
              <v-stepper-content step="1">
                <v-card>
                  <h2 class="ml-2">Choose Plan Type</h2>
                  <v-divider class="mt-1" />
                  <h3 class="ml-2 my-2">
                    Choose whether to make a full DIP or a Prebid
                  </h3>
                  <v-row class="pa-1">
                    <v-col cols="6">
                      <v-btn @click="toggleMode('DIP')" block>
                        <span>
                          <v-icon color="rgba(32, 146, 211)"
                            >mdi-briefcase</v-icon
                          >
                          New DIP
                        </span>
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn @click="toggleMode('Prebid')" block>
                        <span>
                          <v-icon color="rgba(230, 30, 40)"
                            >mdi-clipboard-text</v-icon
                          >
                          New Pre-Bid
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card>
                  <div v-if="toggle == 0">
                    <h2 class="ml-2">Create A New DIP</h2>
                    <v-divider class="mt-1" />
                    <h3 class="ml-2 my-2">Select a Job Number</h3>
                    <ProjectSelect
                      @emitProjectNumber="selectedProjectNumber = $event"
                    />
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="cancel"> Cancel </v-btn>
                      <v-btn
                        v-if="!errorMessage"
                        text
                        color="primary"
                        @click="
                          loading = true;
                          storeResult();
                        "
                        :disabled="loading || !selectedProjectNumber"
                      >
                        Confirm
                      </v-btn>
                    </v-card-actions>
                  </div>

                  <div v-if="toggle == 1">
                    <h2 class="ml-2">Create A New Prebid DIP</h2>
                    <v-divider class="mt-1" />
                    <h3 class="ml-2 my-2">Select a Job Number</h3>
                    <div v-if="JNRequired == false">
                      <ProjectSelect
                        @emitProjectNumber="selectedProjectNumber = $event"
                      />
                    </div>
                    <v-checkbox
                      v-model="JNRequired"
                      @click="selectedProjectNumber = null"
                      label="Create a fake job number for this project?"
                    />
                    <div v-if="JNRequired == true">
                      <v-form v-model="isPrebidFormValid" ref="prebidForm">
                        <v-row>
                          <v-col>
                            <v-text-field
                              label="Auto-generated JN"
                              readonly
                              disabled
                              filled
                              v-model="manualJobNumber"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              label="Job Name"
                              v-model="manualJobName"
                              :rules="stringRules"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="6">
                            <v-text-field
                              label="Client"
                              v-model="manualClient"
                              :rules="stringRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-autocomplete
                              v-model="manualRegion"
                              :items="regionNamesOnly"
                              filled
                              label="Region"
                              :rules="dropdownRules"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              readonly
                              v-model="manualProjectDirectorProxy.Name"
                              label="Project Director"
                              @click="editProjectDirector = true"
                              :rules="dropdownRules"
                            />
                            <v-dialog v-model="editProjectDirector" width="500">
                              <v-card class="removeScroll">
                                <v-card-title class="headline grey lighten-2">
                                  Select a Project Director
                                </v-card-title>
                                <v-container fill-height>
                                  <v-row justify="center" align="center">
                                    <v-col cols="12">
                                      <StaffSelect
                                        :display="editProjectDirector"
                                        :model.sync="manualProjectDirector"
                                        :dialog.sync="editProjectDirector"
                                        @selected="editProjectDirector = false"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </v-dialog>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              readonly
                              v-model="manualProjectManagerProxy.Name"
                              label="Project Manager"
                              @click="editProjectManager = true"
                              :rules="dropdownRules"
                            />
                            <v-dialog v-model="editProjectManager" width="500">
                              <v-card class="removeScroll">
                                <v-card-title class="headline grey lighten-2">
                                  Select a Project Manager
                                </v-card-title>
                                <v-container fill-height>
                                  <v-row justify="center" align="center">
                                    <v-col cols="12">
                                      <StaffSelect
                                        :display="editProjectManager"
                                        :model.sync="manualProjectManager"
                                        :dialog.sync="editProjectManager"
                                        @selected="editProjectManager = false"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </v-dialog>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-autocomplete
                              v-model="manualDisciplines"
                              :items="disciplinesNamesOnly"
                              filled
                              @change="printDisciplines()"
                              multiple
                              label="Disciplines"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-form>
                    </div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="cancel"> Cancel </v-btn>
                      <v-btn
                        v-if="!errorMessage"
                        text
                        color="primary"
                        @click="
                          loading = true;
                          storeResult();
                        "
                        :disabled="
                          (!selectedProjectNumber && !JNRequired) ||
                          (JNRequired && !isPrebidFormValid)
                        "
                      >
                        Confirm
                      </v-btn>
                    </v-card-actions>
                  </div>
                </v-card>
              </v-stepper-content>
            </v-col>
          </v-row>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
  </div>
</template>
<script>
import StaffSelect from "@/components/StaffSelect.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";
import hardcoded from "@/lists/hardcoded.json";
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  components: {
    ProjectSelect,
    StaffSelect,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      errorMessage: null,
      step: 1,
      selectedProjectNumber: null,
      toggle: 0,
      theme: "default",
      JNRequired: null,
      manualJobNumber: "",
      manualJobName: "",
      manualClient: "",
      editProjectDirector: false,
      manualDisciplines: [],
      manualRegion: "",
      harcodedLists: hardcoded,
      manualProjectDirectorProxy: {
        Name: null,
        Email: null,
      },
      editProjectManager: false,
      manualProjectManagerProxy: {
        Name: null,
        Email: null,
      },
      isPrebidFormValid: false,
      stringRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length <= 50) || "Max 50 characters",
      ],
      dropdownRules: [(v) => !!v || "This field is required"],
    };
  },
  watch: {
    dialog: function (newValue, old) {
      //  if(newValue == false){
      // Closing
      console.log("clear manual details here");
      this.manualJobName = "";
      this.manualClient = "";
      this.editProjectDirector = false;
      this.manualProjectDirectorProxy = {
        Name: null,
        Email: null,
      };
      this.editProjectManager = false;
      this.manualProjectManagerProxy = {
        Name: null,
        Email: null,
      };
      this.manualDisciplines = [];
      this.manualRegion = "";
      this.JNRequired = false;
      //  }
    },
  },
  computed: {
    noJobNumberFormIsValid() {
      if (this.JNRequired == true) {
        if (
          this.manualJobNumber == "" ||
          this.manualJobNumber == "" ||
          this.manualJobName == "" ||
          this.manualClient == "" ||
          this.manualProjectDirector.Name == null ||
          this.manualProjectManager.Name == null ||
          this.manualDisciplines.length == 0 ||
          this.manualRegion == ""
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    manualProjectDirector: {
      get() {
        this.manualProjectDirectorProxy =
          this.$store.state.prebidPlan.ProjectDirector;
        return this.$store.state.prebidPlan.ProjectDirector;
      },
      set(val) {
        this.manualProjectDirectorProxy = val;
        this.updateStore(this.manualProjectDirectorProxy, "ProjectDirector");
      },
    },
    manualProjectManager: {
      get() {
        this.manualProjectManagerProxy =
          this.$store.state.prebidPlan.ProjectManager;
        return this.$store.state.prebidPlan.ProjectManager;
      },
      set(val) {
        this.manualProjectManagerProxy = val;
        this.updateStore(this.manualProjectManagerProxy, "ProjectManager");
      },
    },
    disciplinesNamesOnly: {
      get() {
        let output = [];
        this.harcodedLists.disciplines.forEach((element) => {
          output.push(element.DisciplineName);
        });
        return output;
      },
    },
    regionNamesOnly: {
      get() {
        let output = [];
        this.harcodedLists.regions.forEach((element) => {
          output.push(element.RegionName);
        });
        return output;
      },
    },
  },
  mounted() {},

  methods: {
    async getFieldFromParent(projectCode, property, entity, self) {
      try {
        const response = await self.$cds.get(`cds/odata/${entity}`, {
          params: {
            $filter: `ProjectCode eq '${projectCode}'`,
            $select: property,
          },
        });
        return response.data.value[0][property];
      } catch (err) {
        return null;
      }
    },

    async cancel() {
      this.step = 1;
      this.dialog = false;
      this.errorMessage = null;
      this.loading = false;
      this.selectedProjectNumber = null;
    },

    async storeResult() {
      // clear sessions storage if exists
      if (this.toggle === 0) {
        if (this.$session.exists("planData")) {
          this.$session.remove("planData");
        }
        await this.$dip
          .post(
            `${this.$store.state.ApiUri}/CreateResult?JobCode=` +
              this.$store.state.projectInfo.jobCode
          )
          .then((res) => {
            // check fields for null values and search parent to populate them...

            this.$store.state.plan._id = res.data._id;
            this.$store.state.plan.Owner = {
              Email: this.$store.state.user.unique_name,
              Name: this.$store.state.user.name,
            };
            this.$store.state.plan.ProjectName = res.data.ProjectName;
            this.$store.state.plan.ProjectCode = res.data.ProjectCode;

            // res.data.JobNumber // take off last two digits from ProjectCode
            //   ? (this.$store.state.plan.JobNumber = res.data.JobNumber)
            //   : (this.$store.state.plan.JobNumber =
            //       res.data.ProjectCode.substring(0, 6));

            // res.data.Client // if client is empty get it from parent
            //   ? (this.$store.state.plan.Client = res.data.Client)
            //   : (this.$store.state.plan.Client = getFieldFromParent(
            //       res.data.ProjectCode,
            //       "CommissioningClientName",
            //       "Projects",
            //       this
            //     ));

            // something is wrong here

            this.$store.state.plan.JobNumber = res.data.JobNumber;
            this.$store.state.plan.JobCode = res.data.JobCode;

            this.$store.state.plan.Client = res.data.Client;
            this.$store.state.plan.Region = res.data.Region;

            this.$store.state.plan.ProjectManager = res.data.ProjectManager;
            this.$store.state.plan.ProjectDirector = res.data.ProjectDirector;
            this.$store.state.plan.Disciplines = res.data.Disciplines;
            this.$store.state.plan.SurveyId = res.data.SurveyId;
            this.$store.state.plan.Revision = res.data.Revision;
            this.$store.state.plan.Created = res.data.Created;
            this.$store.state.plan.Modified = res.data.Modified;
            this.$store.state.plan.ModifiedBy = res.data.ModifiedBy;
            this.$store.state.plan.SchemaVersion = res.data.SchemaVersion;
            this.$store.state.plan.CompleteScore = res.data.CompleteScore;
            this.$store.state.plan.DigitalPlan.Lock = null;
            this.$store.state.plan.DigitalPlan.DigitalLead = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.DigitalFacilitator = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.DisciplineLeads = [];
            this.$store.state.plan.DigitalPlan.OtherAttendees = [];
            this.$store.state.plan.DigitalPlan.ClientAims = [];
            this.$store.state.plan.DigitalPlan.TeamAims = [];
            this.$store.state.plan.DigitalPlan.PainPoints = [];
            this.$store.state.plan.DigitalPlan.Contract = null;
            this.$store.state.plan.DigitalPlan.ContractPersonResponsible = null;
            this.$store.state.plan.DigitalPlan.ProjectSpecificChoices = [];
            this.$store.state.plan.DigitalPlan.CheckGlobalAutomationSharepoint =
              {
                Name: null,
                Email: null,
              };
            this.$store.state.plan.DigitalPlan.CheckGlobalADEPage = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.CheckSkillsNetworks = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.CheckArupProjects = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.ArupResourceFindings = [];
            this.$store.state.plan.DigitalPlan.ArupDigitalServices = [];
            this.$store.state.plan.DigitalPlan.ArupValueStories = [];
            this.$store.state.plan.DigitalPlan.ExternalSoftwareFindings = [];
            this.$store.state.plan.DigitalPlan.DataRequirements = [];
            this.$store.state.plan.DigitalPlan.MetadataStandards = null;
            this.$store.state.plan.DigitalPlan.MetadataStatus = null;
            this.$store.state.plan.DigitalPlan.DatasetStatus = null;
            this.$store.state.plan.DigitalPlan.MetadataContact = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.DatasetContact = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.EIRSupplied = null;
            this.$store.state.plan.DigitalPlan.EIRSuppliedTrue = null;
            this.$store.state.plan.DigitalPlan.EIRSuppliedFalse = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.BEPSupplied = null;
            this.$store.state.plan.DigitalPlan.BEPSuppliedTrueA = null;
            this.$store.state.plan.DigitalPlan.BEPSuppliedTrueB = null;
            this.$store.state.plan.DigitalPlan.BEPSuppliedFalseA = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.BEPSuppliedFalseB = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.LODAgreements = null;
            this.$store.state.plan.DigitalPlan.LODAgreementsFalse = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.OpenBIMApproach = null;
            this.$store.state.plan.DigitalPlan.OpenBIMApproachTrue = null;
            this.$store.state.plan.DigitalPlan.OpenBIMApproachFalse = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.BIMmmReview = null;
            this.$store.state.plan.DigitalPlan.BIMmmReviewTrue = null;
            this.$store.state.plan.DigitalPlan.BIMmmReviewFalse = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.GISRequirements = null;
            this.$store.state.plan.DigitalPlan.GISRequirementsTrueA = null;
            this.$store.state.plan.DigitalPlan.GISRequirementsTrueB = null;
            this.$store.state.plan.DigitalPlan.GISRequirementsTrueBTrue = null;
            this.$store.state.plan.DigitalPlan.GISRequirementsTrueBFalse = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.GISRequirementsFalse = {
              Name: null,
              Email: null,
            };
            this.$store.state.plan.DigitalPlan.LinkToWhiteboard = null;
            // added to hold status of other actions
            this.$store.state.plan.DigitalPlan.OtherActionsListSummary = [];
            this.$store.state.plan.DigitalPlan.ChallengesOpportunities = [];
            this.$store.state.plan.DigitalPlan.ActionPlan = [];
            this.$store.state.plan.DigitalPlan.PreparationComplete = 0;
            this.$store.state.plan.DigitalPlan.PreWorkComplete = 0;
            this.$store.state.plan.DigitalPlan.WorkshopComplete = 0;
            this.$store.state.plan.DigitalPlan.ActionsComplete = 0;
            this.fullUpdateDIP(
              this.$store.state.plan,
              this.$store.state.ApiUri
            );
          })
          .catch((err) => {
            console.log(err);
            this.errorMessage = err.response.data;
            if (
              this.errorMessage == "Result for Project Code already exists."
            ) {
              this.loading = false;
            }
          })
          .then((res) => {
            if (!this.errorMessage) {
              this.dialog = false;
              this.manualJobName = "";
              this.manualClient = "";
              this.editProjectDirector = false;
              this.manualProjectDirectorProxy = {
                Name: null,
                Email: null,
              };
              this.manualProjectDirector = {
                Name: null,
                Email: null,
              };
              this.editProjectManager = false;
              this.manualProjectManagerProxy = {
                Name: null,
                Email: null,
              };
              this.manualProjectManager = {
                Name: null,
                Email: null,
              };
              this.manualDisciplines = [];
              this.manualRegion = "";
              this.JNRequired = false;
              console.log("routing to preparation");
              this.$router.push({ name: "Preparation" });
            }
          });
      } else {
        // prebid is selected, now check if a job number is required
        // if a JN is required, send form data along with request.
        if (this.JNRequired) {
          console.log(`job number required = ${this.JNRequired}`);
          await this.$dip
            .post(
              `${this.$store.state.ApiUri}/CreatePreBidResult?ProjectCode=` +
                "custom",
              {
                ProjectCode: this.manualJobNumber,
                JobNumber: this.manualJobNumber,
                JobName: this.manualJobName,
                Client: this.manualClient,
                ProjectDirector: this.manualProjectDirector,
                ProjectManager: this.manualProjectManager,
                Disciplines: this.manualDisciplines,
                Region: this.manualRegion,
              }
            )
            .then((res) => {
              console.log(res);
              console.log(this.manualJobNumber);
              console.log(this.manualJobName);
              console.log(this.manualClient);
              console.log(this.manualProjectDirector);
              console.log(this.manualProjectManager);
              console.log(this.manualRegion);
              console.log(this.manualDisciplines);
              console.log("----------------------");

              // set prebid object to defaults here
              this.$store.state.prebidPlan._id = res.data._id;
              this.$store.state.prebidPlan.Owner = {
                Email: this.$store.state.user.unique_name,
                Name: this.$store.state.user.name,
              };
              this.$store.state.prebidPlan.ProjectName = res.data.ProjectName;
              this.$store.state.prebidPlan.JobNumber = res.data.JobNumber;
              this.$store.state.prebidPlan.Client = res.data.Client;
              this.$store.state.prebidPlan.ProjectCode = res.data.ProjectCode;
              this.$store.state.prebidPlan.Region = res.data.Region;
              this.$store.state.prebidPlan.ProjectManager =
                res.data.ProjectManager;
              this.$store.state.prebidPlan.ProjectDirector =
                res.data.ProjectDirector;
              this.$store.state.prebidPlan.Disciplines = res.data.Disciplines;
              this.$store.state.prebidPlan.SurveyId = res.data.SurveyId;
              this.$store.state.prebidPlan.Revision = res.data.Revision;
              this.$store.state.prebidPlan.Created = res.data.Created;
              this.$store.state.prebidPlan.Modified = res.data.Modified;
              this.$store.state.prebidPlan.ModifiedBy = res.data.ModifiedBy;
              this.$store.state.prebidPlan.SchemaVersion =
                res.data.SchemaVersion;
              this.$store.state.prebidPlan.CompleteScore =
                res.data.CompleteScore;
              // set prebid specifics defaults here
              (this.$store.state.prebidPlan.PreBidPlan.Lock = null),
                (this.$store.state.prebidPlan.PreBidPlan.DigitalOpportunities =
                  []),
                (this.$store.state.prebidPlan.PreBidPlan.DigitalOpportunityActions =
                  []),
                (this.$store.state.prebidPlan.PreBidPlan.DigitalValueStories =
                  []),
                (this.$store.state.prebidPlan.PreBidPlan.ReadyDigitalServices =
                  []),
                (this.$store.state.prebidPlan.PreBidPlan.ContractChecked =
                  null),
                (this.$store.state.prebidPlan.PreBidPlan.ContractActions = []),
                // save prebid plan here
                // must set prebid object and modified by
                this.prebidUpdateDIP(
                  this.$store.state.prebidPlan,
                  this.$store.state.ApiUri
                );
            })
            .catch((err) => {
              this.errorMessage = err.response.data;
              console.log(this.manualJobNumber);
              console.log(this.manualJobName);
              console.log(this.manualClient);
              console.log(this.manualProjectDirector);
              console.log(this.manualProjectManager);
              console.log(this.manualRegion);
              console.log(this.manualDisciplines);
              console.log("----------------------");
              console.log(err.response.data);
            })
            .then((res) => {
              if (!this.errorMessage) {
                this.dialog = false;
                this.manualJobName = "";
                this.manualClient = "";
                this.editProjectDirector = false;
                this.manualProjectDirectorProxy = {
                  Name: null,
                  Email: null,
                };
                this.manualProjectDirector = {
                  Name: null,
                  Email: null,
                };
                this.editProjectManager = false;
                this.manualProjectManagerProxy = {
                  Name: null,
                  Email: null,
                };
                this.manualProjectManager = {
                  Name: null,
                  Email: null,
                };
                this.manualDisciplines = [];
                this.manualRegion = "";
                this.JNRequired = false;
                this.$router.push({ name: "PrebidForm" });
              }
            });
        } else {
          await this.$dip
            .post(
              `${this.$store.state.ApiUri}/CreatePreBidResult?ProjectCode=` +
                this.$store.state.projectInfo.projectCode
            )
            .then((res) => {
              console.log(res);
              // set prebid object to defaults here
              this.$store.state.prebidPlan._id = res.data._id;
              this.$store.state.prebidPlan.Owner = {
                Email: this.$store.state.user.unique_name,
                Name: this.$store.state.user.name,
              };
              this.$store.state.prebidPlan.ProjectName = res.data.ProjectName;
              this.$store.state.prebidPlan.JobNumber = res.data.JobNumber;
              this.$store.state.prebidPlan.Client = res.data.Client;
              this.$store.state.prebidPlan.ProjectCode = res.data.ProjectCode;
              this.$store.state.prebidPlan.Region = res.data.Region;
              this.$store.state.prebidPlan.ProjectManager =
                res.data.ProjectManager;
              this.$store.state.prebidPlan.ProjectDirector =
                res.data.ProjectDirector;
              this.$store.state.prebidPlan.Disciplines = res.data.Disciplines;
              this.$store.state.prebidPlan.SurveyId = res.data.SurveyId;
              this.$store.state.prebidPlan.Revision = res.data.Revision;
              this.$store.state.prebidPlan.Created = res.data.Created;
              this.$store.state.prebidPlan.Modified = res.data.Modified;
              this.$store.state.prebidPlan.ModifiedBy = res.data.ModifiedBy;
              this.$store.state.prebidPlan.SchemaVersion =
                res.data.SchemaVersion;
              this.$store.state.prebidPlan.CompleteScore =
                res.data.CompleteScore;
              // set prebid specifics defaults here
              (this.$store.state.prebidPlan.PreBidPlan.Lock = null),
                (this.$store.state.prebidPlan.PreBidPlan.DigitalOpportunities =
                  []),
                (this.$store.state.prebidPlan.PreBidPlan.DigitalOpportunityActions =
                  []),
                (this.$store.state.prebidPlan.PreBidPlan.DigitalValueStories =
                  []),
                (this.$store.state.prebidPlan.PreBidPlan.ReadyDigitalServices =
                  []),
                (this.$store.state.prebidPlan.PreBidPlan.ContractChecked =
                  null),
                (this.$store.state.prebidPlan.PreBidPlan.ContractActions = []),
                // save prebid plan here
                // must set prebid object and modified by
                this.prebidUpdateDIP(
                  this.$store.state.prebidPlan,
                  this.$store.state.ApiUri
                );
            })
            .catch((err) => {
              this.errorMessage = err.response.data;
            })
            .then((res) => {
              if (!this.errorMessage) {
                this.dialog = false;
                this.manualJobName = "";
                this.manualClient = "";
                this.editProjectDirector = false;
                this.manualProjectDirectorProxy = {
                  Name: null,
                  Email: null,
                };
                this.manualProjectDirector = {
                  Name: null,
                  Email: null,
                };
                this.editProjectManager = false;
                this.manualProjectManagerProxy = {
                  Name: null,
                  Email: null,
                };
                this.manualProjectManager = {
                  Name: null,
                  Email: null,
                };
                this.manualDisciplines = [];
                this.manualRegion = "";
                this.JNRequired = false;
                this.$router.push({ name: "PrebidForm" });
              }
            });
        }
      }
    },
    printDisciplines() {
      console.log(this.manualDisciplines);
    },
    updateStore(newVal, property) {
      this.$store.state.prebidPlan[property] = newVal;
    },
    prebidJN(bool) {
      if (bool) {
        this.JNRequired = false;
      } else {
        this.JNRequired = true;
        this.manualJobNumber = this.$uuid.v4();
      }
      console.log(this.JNRequired);
    },
    toggleMode(type) {
      if (type == "DIP") {
        this.toggle = 0;
      } else {
        this.toggle = 1;
      }
      this.step = 2;
    },
  },
};
</script>
<style src="../styles/forms.scss" lang="scss"></style>
